import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import process1 from '../../assets/images/process/process1.png'
import process2 from '../../assets/images/process/process2.png'
import process3 from '../../assets/images/process/process3.png'
import process4 from '../../assets/images/process/process4.png'
import process5 from '../../assets/images/process/process5.png'
import process6 from '../../assets/images/process/process6.png'
import shape from '../../assets/images/shape/circle-shape1.png'

const HowItWork = () => {
    return (
        <section className="process-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="about" /> 
                        How It's Work
                    </span>
                    <h2>Full Data Management Process Support</h2>
                    <p>Any Data Management project follows the principles mentioned below, and we support our clients in each. With the support of the best technologies on the market, we help our clients to get the best value out of their data.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-process-box">
                            <div className="number">1</div>
                            <div className="image">
                                <img src={process1} alt="about" />
                            </div>
                            <h3>Frame the Problem</h3>
                            <p>Initiatives are not that straight-forward, so, setting the realistic and complete expectations is one of the most important step in a successful implementation.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-process-box">
                            <div className="number">2</div>
                            <div className="image">
                                <img src={process2} alt="about" />
                            </div>
                            <h3>Collect the Raw Data</h3>
                            <p>Knowing your data answers to most of the questions. By cataloging, cleansing and analyzing your data, we can choose the perfect match for analytic calculations.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-process-box">
                            <div className="number">3</div>
                            <div className="image">
                                <img src={process3} alt="about" />
                            </div>
                            <h3>Process the Data</h3>
                            <p>Processing data covers multiple levels of aggregation and ensures enrichment and correlation of it. This ensures support of the initiative.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-process-box ">
                            <div className="number">4</div>
                            <div className="image">
                                <img src={process4} alt="about" />
                            </div>
                            <h3>Explore the Data</h3>
                            <p>Exploring at this point helps develop a better understanding of any preliminary trends and correlations. Doesn't mean you're already reporting, testing hypotheses or evaluating predictions.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-process-box">
                            <div className="number">5</div>
                            <div className="image">
                                <img src={process5} alt="about" />
                            </div>
                            <h3>Perform In-depth Analysis</h3>
                            <p>The In-depth analysis is represented by defining models, implementing supervised or unsupervised algorithms that deliver analytical or quality measurements.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-process-box">
                            <div className="number">6</div>
                            <div className="image">
                                <img src={process6} alt="about" />
                            </div>
                            <h3>Data & Results Reporting</h3>
                            <p>Final step is always taking the results and interpret them. It is very important to balance well the results as well as to frame them into the correct context.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape} alt="about" />
            </div>
        </section>
    )
}

export default HowItWork