import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../../assets/images/about/about-img5.png'
import starIcon from '../../assets/images/star-icon.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/icon6.png'
import icon7 from '../../assets/images/icons/icon7.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'
 
const AboutUsContent = () => {
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    About Us
                                </span>
                                <h2>Drive Digital Revolution Through Data Management</h2>
                                <p>Starting from specific methods, processes, algorithms and systems, we support the process to extrapolate knowledge and insights from structured and unstructured data.</p>
                                <ul className="features-list">
                                    <li>
                                        <img src={icon4} alt="banner" />
                                        <h3>10 Years</h3>
                                        <p>On the market</p>
                                    </li>
                                    <li>
                                        <img src={icon5} alt="banner" />
                                        <h3>4+</h3>
                                        <p>Major Clients</p>
                                    </li>
                                    <li>
                                        <img src={icon6} alt="banner" />
                                        <h3>100%</h3>
                                        <p>Satisfaction rate</p>
                                    </li>
                                    <li>
                                        <img src={icon7} alt="banner" />
                                        <h3>25%</h3>
                                        <p>Industries covered</p>
                                    </li>
                                </ul>
                                <p>Our main goal is to deliver not only software, experience and performant solutions but also help the client to embrace them and get necessary knowledge over the technologies.</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our Competencies</h3>
                                <p>Full data management solutions tailored for the client.</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Analysis</li>
                                    <li><i className="flaticon-tick"></i> Development</li>
                                    <li><i className="flaticon-tick"></i> Testing</li>
                                    <li><i className="flaticon-tick"></i> Reporting</li>
                                </ul>
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our Mission</h3>
                                <p>The goal is to empower the clients with means and knowledge on how to manage their data.</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Best practises</li>
                                    <li><i className="flaticon-tick"></i> Technology</li>
                                    <li><i className="flaticon-tick"></i> Architecture</li>
                                    <li><i className="flaticon-tick"></i> Trainings</li>
                                </ul>
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div className="about-text">
                                <h3>Who we are</h3>
                                <p>We put emphasis on smart and consolidated solutions that are custom tailored for each client.</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> AbInitio</li>
                                    <li><i className="flaticon-tick"></i> Multiple Industries</li>
                                    <li><i className="flaticon-tick"></i> Client's ROI</li>
                                    <li><i className="flaticon-tick"></i> Life Cycle & Growth</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>
        </section>
    )
}

export default AboutUsContent;