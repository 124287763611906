import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import howItWork from '../../assets/images/how-its-work.png'

const WhyChooseUs = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" /> 
                                Why Choose Us?
                            </span>
                            <h2>We value data</h2>
                            <p>In any business, knowing your data is important, but sometimes it is even more important to know how to value it and this is why one of our major concern in solution designs is to tailor it for the client.</p>
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Data Knowledge</h3>
                                    <p>We support data identification and documentation that measures and improve the internal knowledge of it.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>Analytics</h3>
                                    <p>Delivering analytical solutions, we support you to take valuable decisions, backed by the reality of your data.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>Develop your Business</h3>
                                    <p>Performant and Scalable solutions ensures the bases of a healthy and wealthy growth.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={howItWork} alt="banner" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs